.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.headeritemssections{
  position: relative;
  justify-content: flex-end;
}


@media print {
  @page { 
    margin: auto;
    display: block;
    width: 90%;
  }
  .toggleIonsPlusicons{
    display: none !important;
  }
}

.button {
  font-family: "Roboto", Arial, sans-serif;
  color: #ffffff;
  cursor: pointer;
  padding: 6px 30px;
  display: inline-block;
  letter-spacing: 1.5px;
  font-size: 1em;
  outline: none;
  position: relative;
  font-size: 14px;
  border: 0px solid #cfcece;
  background-color: #bb1ccc !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.button:hover,
.button.hover {
  border-color: #2980b9;
}
/*
card 
*/
.card {
  font-size: 12px;
}
.card > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 14px;
  text-align: center;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
  background: #bb1ccc !important;
  padding: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

}
.modal > .content {
  width: 100%;
  overflow: hidden !important;
  overflow-y: auto !important;
  height: 500px;


}
.modal > .actions {
  margin: auto;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  text-align: center;
  background: #eee;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  border-radius: 18px;
  font-size: 35px;
  right: 10px;
  top: 20px;
  padding-top: 0px;
  color: #bb1ccc;
}

.example-warper-start {
  width: 100%;
  padding: 20px 5%;
  display: flex;
  flex-wrap: wrap;
  border: 1px #cfcece dashed;
}
.popup-overlay{
  background-color: rgba(0, 0, 0, 0.5) !important;
}

#NewTable_12345 tr:nth-child(even) {
  background-color: rgb(247, 249, 252);
}

#NewTable th {
  background-color: #eee;
  color: #253978;
}
.circleDivNew{
  background: rgb(238, 238, 238);
  border-radius: 100%;
  color: #253978;
  text-align: center;
  display: grid;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 20px;
  width: 40px;
  height: 40px;
  justify-content: center;
}
.SelectItems{
  border: 1px solid #d0d8e2;
  outline: none;
  color: #777;
  border-radius: 7px;
  padding: 9px;
}

.print_sagments{
  display: grid;
  justify-content: end;
  font-size: 16px;
  font-weight: 700;
  color: #253978;
  cursor: pointer;
}
.MuiTable-root {
  border-collapse: separate !important;
  border-spacing: 3px !important;
}
.MuiPaper-elevation2 {
  box-shadow: none !important;
}
.MuiTableCell-head{
    background: rgb(208, 216, 226) !important;
    color: rgb(37, 57, 120) !important;
    font-weight: 600 !important;
}
.MuiTable-root tr, .MuiTableRow-footer td{
  border: none !important;
}
.Component-paginationToolbar-2{
  border: none !important;

}
.MTableToolbar-searchField-10 .MuiInputBase-formControl {
  border-bottom: none !important;
  border: 1px solid #d0d8e2 !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  padding: 10px !important;
  padding-left: 10px!important;
  width: 400px !important;
  margin-bottom: 2rem;
  margin-top: 2rem;

}
.MuiInput-underline:before {
  border-bottom: none !important;
  outline: none !important;

}
 .MuiToolbar-gutters{
  padding-left: 0px!important;

}
.MuiTable-root tr{
  min-height: 100px !important;
  height: auto !important;

}
.MuiToolbar-root .MuiInput-formControl{
  border-bottom: none !important;
  border: 2px solid #d0d8e2 !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  padding: 12px !important;
  padding-left: 10px!important;
  width: 400px !important;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.MuiIconButton-root {
  background-color: transparent !important;
}

.isloadingFilter{
  padding: 2rem;
}
.workInprogress{
   background-color: #4f66d3 !important;
   color: white !important;
   font-weight: normal !important;
   cursor: pointer;
}
.closedComplete{
  background-color: rgb(144, 238, 144) !important;
  color: green !important;
  font-weight: normal !important;


}
.closedIncomplete{
  background-color: red !important;
  
  color: white !important;
  font-weight: normal !important;

}
.cancelled{
  background-color: red !important;
  color: white !important;
  font-weight: normal !important;
}
.state_na_in_snow{
  font-weight: normal !important;
}
span title{
    color: red !important;
}
.workflowTable tr{
  font-size: 14px;
}
.custom-selectworkflow{
  font-size: 14px;

}
.SearchBox_newworkflow{
    font-size: 14px;
    border: 1px solid #d0d8e2;
    border-radius: 6px;
    padding: 10px;
    padding-left: 10px!important;
    padding-top: 10px!important;
    width: 400px;
}
.tableShadow{
  box-shadow: none !important;
}

.visuals_container{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  align-content: baseline;
  align-items: baseline;
}
.visuals_container > div {
  flex: 47%; /* or - flex: 0 50% - or - flex-basis: 50% - */
  /*demo*/
}


.notificationsItems{
  display: flex;
  grid-gap: 20px;
  padding: 18px;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  border-bottom: 2px solid #eee;
  align-content: center;
}

.iconsNoti{
width: 40px;
height: 40px;
border-radius: 100%;
display: grid;
justify-content: center;
align-items: center;
align-content: center;
color: #f75454;
}
.vendorNameCls{
font-size: 16px;
font-weight: 600;
color: #253978;
}
.RiskDown{
font-weight: 600;
}
.high{
color: red;
background-color: #eed7d7;
padding-top: 2px;
padding-bottom: 6px;
padding-right: 6px;
padding-left: 6px;
border-radius: 4px;
font-weight: normal;

}
.low{
color: #2a9c26;
background-color: lightgreen;
padding-top: 2px;
padding-bottom: 6px;
padding-right: 6px;
padding-left: 6px;
border-radius: 4px;
font-weight: normal;

}
.warrning{
color: #e9891c;
display: flex;
grid-gap: 10px;
align-items: center;
align-content: center;
font-weight: normal;

}
.iconsNotiLow{
color: #2a9c26;
}

#notificationsDiv::-webkit-scrollbar {
display: none;
}
.sticky {
position: -webkit-sticky;
position: sticky;
top: 0;
background: white;
color:#0000A0;
padding: 15px;
text-align: left;
font-size: 18px;
font-weight: 600;
width:100%;
z-index: 1;
border-bottom:2px solid #cfcece ;
}

.dialogCls ::after {
  content: "";
  position: fixed;
  bottom: 100%;
  margin-left: -17px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent #0000A0 transparent;
  top: 70px;
  right: 25%;
}
.vendorNameCls a{
  color: #0000A0 !important;
}
.recentSearchDiv{
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  align-items: center;

}
.recetSearch-title{
  text-align: left;
  width: 100%;
  justify-content: flex-start;
  display: grid;
  padding: 0px;
  padding-left: 1.2rem;
  font-size: 18px;
  font-weight: 600;
  padding-top: 10px;
}
.notificationDrawer .MuiBackdrop-root{
    background-color: transparent !important;
}
.notificationDrawer .MuiPaper-root{
    width: auto !important;
    max-width: 40% !important;
    min-width: 25% !important;
    top: 76px !important;
    height: calc(100vh - 78px) !important;
}

.notificationDrawer .MuiPaper-root .MuiBox-root{
  width: 100% !important;
}

.notificationDrawer::-webkit-scrollbar {
  display: none !important;
}

.pieChatsDiv{
  background-color: white;
  position: relative;
  z-index: 0;
  padding: 1rem;
}

.serachItemList{
  display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    padding: 0px 10px;
    border-bottom: 2px solid #eee;
}
.MuiListItemText-primary{
  width: 90% !important;
  display: inline-block !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  -webkit-line-clamp: 1 !important; 
}
.MuiButton-textPrimary:hover{
  background-color: white !important;
}

#remove .MuiButton-textPrimary:hover{
  background-color: white !important;
  color: red !important;
}

#save .MuiButton-textPrimary:hover{
  background-color: white !important;
  color: rgb(60, 255, 0) !important;
}
.NoNotifications{
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #0000a0;
}

.aletrtCls{
  position: fixed !important;
  top: 10px !important;
  right: 21px !important;
}

.chart-container .chartjs-render-monitor .chartjs-dataset .chartjs-bar {
  cursor: pointer !important;
}

.Toastify__toast-container{
  width: auto !important;
  min-width: 300px !important;
  max-width: 500px !important;
}

.notificationUnseen{
    background: #f7f9fc !important;
}
.notificationRead{
    background-color: #ffffff !important;
}
