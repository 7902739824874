.login_body_grid{
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 65% 35%;
    overflow: hidden;
}
.left_grid_text{
    color: white;
    width: 90%;
    margin: auto;
    display: block;
}
.right_grid_align{
    height: 100%;
    display: grid;
    align-content: center;
}
.btn_login{
    height: 40px;
    color: #3d3d3d;
    background-color: #ffffff;
    border: none;
    width: 70%;
    border-radius: 6px;
    font-weight: 600;
}
.left_margin{
    margin-top: 20px;
    padding-bottom: 20px !important;
}

.logout{
    cursor: pointer;
}